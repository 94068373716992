import AuthCard from "@/components/AuthCard";
import AuthSessionStatus from "@/components/AuthSessionStatus";
import AuthValidationErrors from "@/components/AuthValidationErrors";
import GuestLayout from "@/components/layouts/GuestLayout";
import Label from "@/components/Label";
import Link from "next/link";
import { useAuth } from "@/hooks/auth";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Image from "next/image";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import {
    classNames,
    emailChangeNotificationHandler,
    formatTime,
} from "@/utilities";
import ButtonText from "@/components/ButtonText";
import Notification from "@/components/Notification";
import { useRouter } from "next/router";
import FormikInput from "@/components/FormikInput";
import { GoogleLogin } from "@react-oauth/google";
import useLoader from "@/hooks/loader";
import axiosAuth from "@/lib/axiosAuth";

const Login = ({ shareCode, redirect_to, email, reset, email_change }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({
        email: email ?? "",
        password: "",
        otp: "",
        mode: "email",
    });

    const router = useRouter();
    const loader = useLoader();
    const { query } = router;


    if (email) {
        email = decodeURIComponent(email);
    }
    const { login, googleLogin, logout } = useAuth({
        middleware: "guest",
        redirectIfAuthenticated: {
            company: redirect_to ?? "/company/dashboard",
            engineer: redirect_to ?? "/engineer/interviews",
            fallback: redirect_to ?? "/",
        },
    });

    const schema = Yup.object().shape({
        mode: Yup.string().required("Please select a mode."),
        email: Yup.string()
            .required("Please enter your email id.")
            .email("Please enter a valid email id."),
        password: Yup.string().required("Please enter your password."),
        otp: Yup.string().when("mode", {
            is: "otp",
            then: Yup.string()
                .min(6, "Please enter a valid otp.")
                .max(6, "Please enter a valid otp.")
                .required(),
            otherwise: Yup.string().notRequired(),
        }),
    });

    const [error, setErrors] = useState([]);
    const [status, setStatus] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [onLoading, setOnLoading] = useState(false);
    const [notification, setNotification] = useState(null);

    // logout when query has async logout parameter, which is recieved from async-web-app

    useEffect(async () => {
        if (query?.type){
            if (query?.type === "error") {
                await googleSignInErrorHandler();
            } else if(query?.type === "success") {
                setNotification({
                    message:
                        "Login successful!",
                    type: "success",
                });
                setTimeout(() => setNotification(null), 5000);
            }
            const url = new URL(window.location.href);
            url.searchParams.delete("type");
            window.history.replaceState({}, '', url.toString());
        }
    }, [query.type]);

    useEffect(async () => {
        if (query?.sessionexpired){
            setNotification({
                message:
                    "Session Expired!",
                type: "error",
            });
            setTimeout(() => setNotification(null), 5000);
        }
        const url = new URL(window.location.href);
        url.searchParams.delete("sessionexpired");
        window.history.replaceState({}, '', url.toString());
    }, [query.sessionexpired]);
    
    useEffect(async () => {
        if (query.redirect === "async-meet") {
            await logout();
        }
    }, [query.message]);

    useEffect(async () => {
        if (email_change) {
            setNotification(emailChangeNotificationHandler(email_change));

            await router.push(
                {
                    pathname: "/login",
                    query: {
                        ...(shareCode && { share_code: shareCode }),
                        ...(redirect_to && { redirect_to }),
                        ...(email && { email }),
                        ...(reset && { reset }),
                    },
                },
                undefined,
                { shallow: true }
            );
            setTimeout(() => setNotification(null), 5000);
        }
    }, [email_change]);

    useEffect(() => {
        if (reset?.length > 0 && error.length === 0) {
            setStatus(atob(reset));
        } else {
            setStatus(null);
        }
    });

    useEffect(() => {
        if (error.length > 0) {
            setOnLoading(false);
        }
    }, [error]);

    const googleSignInErrorHandler = () => {
        setNotification({
            message:
                "Google sign-up isn't working currently, please use email!",
            type: "error",
        });

        setTimeout(() => setNotification(null), 5000);
    };

    const sendVerificationCode = async (
        email,
        password,
        onLoading,
        onSuccess
    ) => {
        onLoading(true);
        axiosAuth
            .post(`/api/auth/2fa-check`, {
                email: email,
                password: password,
            })
            .then(async (res) => {
                if (res.data.needed_2fa) {
                    setNotification({
                        message: res.data.message,
                        type: "success",
                    });
                    setTimeout(() => setNotification(null), 5000);
                } else if (!res.data.needed_2fa) {
                    const payload = {
                        email: email,
                        password: password,
                        ...(shareCode && {
                            share_code: shareCode,
                        }),
                    };
                    return await login({
                        payload,
                        setErrors,
                        setStatus,
                    });
                }
                onSuccess(res.data);
            })
            .catch((err) => {
                setNotification({
                    message:
                        err?.response?.data?.message || "Something went wrong",
                    type: "error",
                });
                setTimeout(() => setNotification(null), 5000);
            })
            .finally(() => {
                onLoading(false);
            });
    };

    const handleOnSubmit = async (values, actions) => {
        if (values.mode === "email") {
            await sendVerificationCode(values.email, values.password, setIsLoading, (res) => {
                if (res.needed_2fa) {
                    actions.setFieldValue("mode", "otp");
                } else {
                    const payload = {
                        email: values.email,
                        password: values.password,
                    };
                    login({
                        payload,
                        setErrors,
                        setStatus,
                    });
                }
            });
        } else if (values.mode === "otp"){
            const payload = {
                email: values.email,
                password: values.password,
                code: values.otp,
                ...(shareCode && {
                    share_code: shareCode,
                }),
            };
            return await login({
                payload,
                setErrors,
                setStatus,
            });
        }
    };

    // const { hideBanner } = useSelector((store) => store.other);

    return (
        <>
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                />
            )}
            <GuestLayout headers={["Login"]}>
                <AuthCard>
                    <div className="mx-auto w-full max-w-sm p-2 bg-primary h-auto">
                        <div>
                            <Link href="/">
                                <div className="flex justify-center">
                                    <Image
                                        src="/logos/tacnique-logo.svg"
                                        alt="Tacnique"
                                        width={169}
                                        height={48}
                                        layout="intrinsic"
                                    />
                                </div>
                            </Link>
                            <h1 className="my-4 tracking-tight text-gray-900 text-center font-normal text-lg">
                                tech hiring,
                                <span className="text-primary-600 pl-1">
                                    reinvented.
                                </span>
                            </h1>
                        </div>

                        <div>
                            <div className="mt-2">
                                <Formik
                                    enableReinitialize
                                    initialValues={initialFormValues}
                                    onSubmit={handleOnSubmit}
                                    validationSchema={schema}
                                    validateOnChange={false}
                                    validateOnBlur={false}
                                >
                                    {(props) => {
                                        const {
                                            touched,
                                            errors,
                                            values,
                                            handleSubmit,
                                            setFieldValue,
                                        } = props;
                                        return (
                                            <form
                                                onSubmit={handleSubmit}
                                                className="space-y-4"
                                            >
                                                <>
                                                    <div>
                                                        <div className="flex justify-between">
                                                            <Label
                                                                htmlFor="email"
                                                                className="required-label"
                                                            >
                                                                Email Address
                                                            </Label>
                                                            {values.mode !==
                                                                "email" && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setFieldValue(
                                                                            "otp",
                                                                            ""
                                                                        );
                                                                        setFieldValue(
                                                                            "password",
                                                                            ""
                                                                        );
                                                                        setFieldValue(
                                                                            "mode",
                                                                            "email"
                                                                        );
                                                                    }}
                                                                    className="text-xs font-medium text-gray-500"
                                                                >
                                                                    Change Email
                                                                </button>
                                                            )}
                                                        </div>
                                                        <div className="flex relative grow">
                                                            <div className="top-3 ml-3 w-3.5 h-3.5 absolute">
                                                                <Image
                                                                    src="/work-email.svg"
                                                                    alt="email"
                                                                    width={14}
                                                                    height={14}
                                                                    layout="fixed"
                                                                />
                                                            </div>

                                                            <Field
                                                                disabled={
                                                                    values.mode !==
                                                                    "email"
                                                                }
                                                                name="email"
                                                                type="text"
                                                                customclassname="pl-9"
                                                                component={
                                                                    FormikInput
                                                                }
                                                                autoFocus={true}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Label
                                                            htmlFor="password"
                                                            className="required-label"
                                                        >
                                                            Password
                                                        </Label>
                                                        <div>
                                                            <div className="relative flex grow">
                                                                <div className="top-1/3 ml-3 w-3.5 h-3.5 absolute">
                                                                    <Image
                                                                        src="/key-login-protection-icon.svg"
                                                                        alt="password"
                                                                        width={
                                                                            14
                                                                        }
                                                                        height={
                                                                            14
                                                                        }
                                                                        layout="fixed"
                                                                    />
                                                                </div>

                                                                <Field
                                                                    type={
                                                                        showPassword
                                                                            ? "text"
                                                                            : "password"
                                                                    }
                                                                    name="password"
                                                                    id="password"
                                                                    className={classNames(
                                                                        errors.password &&
                                                                            touched.password
                                                                            ? "error focus:border-red-500"
                                                                            : "",
                                                                        "pl-9 block w-full appearance-none bg-white-100 rounded border-gray-200 px-3 py-2 shadow-sm focus:border-primary-500 focus:ring-opacity-0 focus:ring-primary-100 focus:outline-none sm:text-sm"
                                                                    )}
                                                                    data-test-id="password"
                                                                />
                                                                <div className="absolute pr-3 flex items-center text-sm leading-5 top-1/3 right-0">
                                                                    <button
                                                                        type="button"
                                                                        className={`h-3 text-gray-500 ${
                                                                            showPassword
                                                                                ? "block"
                                                                                : "hidden"
                                                                        }`}
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                false
                                                                            )
                                                                        }
                                                                        tabIndex="-1"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faEye
                                                                            }
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className={`h-3 text-gray-400 cursor-pointer ${
                                                                            showPassword
                                                                                ? "hidden"
                                                                                : "block"
                                                                        }`}
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                true
                                                                            )
                                                                        }
                                                                        tabIndex="-1"
                                                                    >
                                                                        <FontAwesomeIcon
                                                                            icon={
                                                                                faEyeSlash
                                                                            }
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {errors.password &&
                                                                touched.password && (
                                                                    <span className="text-red-500 text-xs">
                                                                        {
                                                                            errors.password
                                                                        }
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>
                                                    {values.mode === "otp" && (
                                                        <div className="mt-4">
                                                            <div className="flex justify-between">
                                                                <Label
                                                                    htmlFor="otp"
                                                                    className="required-label"
                                                                >
                                                                    Enter OTP
                                                                </Label>
                                                                <ResendOTP
                                                                    mode={
                                                                        values.mode
                                                                    }
                                                                    setFieldValue={
                                                                        setFieldValue
                                                                    }
                                                                    email={
                                                                        values.email
                                                                    }
                                                                    password={
                                                                        values.password
                                                                    }
                                                                    sendVerificationCode={
                                                                        sendVerificationCode
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="flex relative grow">
                                                                <div className="top-3 ml-3 w-3.5 h-3.5 absolute">
                                                                    <Image
                                                                        src="/key-login-protection-icon.svg"
                                                                        alt="otp"
                                                                        width={
                                                                            35
                                                                        }
                                                                        height={
                                                                            35
                                                                        }
                                                                        objectFit="contain"
                                                                        loading="lazy"
                                                                    />
                                                                </div>
                                                                <Field
                                                                    name="otp"
                                                                    type="number"
                                                                    id="number-field"
                                                                    customclassname={classNames(
                                                                        "pl-9"
                                                                    )}
                                                                    component={
                                                                        FormikInput
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className="flex flex-col gap-3 items-center justify-between">
                                                        <button
                                                            type="submit"
                                                            data-test-id="submit"
                                                            id="login"
                                                            disabled={
                                                                isLoading ||
                                                                onLoading
                                                            }
                                                            className="transition ease-in-out delay-150 duration-300 flex w-full justify-center rounded-3xl border border-transparent bg-primary-600 py-2 px-8 text-sm font-bold text-white shadow-sm hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                                                        >
                                                            <ButtonText
                                                                text="Login"
                                                                loading={
                                                                    onLoading ||
                                                                    isLoading
                                                                }
                                                                classes="absolute right-11"
                                                            />
                                                        </button>

                                                        {process.env
                                                            .NEXT_PUBLIC_GOOGLE_WEBAPP_CLIENT_ID && (
                                                            <GoogleLogin
                                                                onSuccess={async (
                                                                    response
                                                                ) => {
                                                                    loader.start();

                                                                    const clientId =
                                                                        process
                                                                            .env
                                                                            .NEXT_PUBLIC_GOOGLE_WEBAPP_CLIENT_ID;

                                                                    const {
                                                                        credential,
                                                                    } = response;

                                                                    googleLogin(
                                                                        {
                                                                            clientId,
                                                                            credential,
                                                                            redirect_to,
                                                                            setErrors,
                                                                            setStatus,
                                                                        }
                                                                    );
                                                                }}
                                                                onError={() =>
                                                                    googleSignInErrorHandler()
                                                                }
                                                                shape={"pill"}
                                                                width={"370px"}
                                                                logo_alignment={
                                                                    "center"
                                                                }
                                                                text={
                                                                    "signin_with"
                                                                }
                                                                context={
                                                                    "signin"
                                                                }
                                                                useOneTap
                                                            />
                                                        )}
                                                    </div>

                                                    <AuthSessionStatus
                                                        className="my-4"
                                                        status={status}
                                                    />

                                                    <AuthValidationErrors
                                                        className="my-4"
                                                        errors={error}
                                                    />

                                                    <div className="mt-7 w-full flex items-end flex-col">
                                                        <Link href="/forgot-password">
                                                            <a className="text-sm text-right text-primary-600 hover:text-primary-800 mb-2">
                                                                Forgot your
                                                                password?
                                                            </a>
                                                        </Link>

                                                        <div className="flex items-center">
                                                            <span className="text-sm mr-2">
                                                                Not a member?
                                                            </span>
                                                            <Link href="/signup">
                                                                <a
                                                                    className={`text-sm text-primary-600 hover:text-primary-800`}
                                                                >
                                                                    Sign up
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </AuthCard>
            </GuestLayout>
        </>
    );
};

const ResendOTP = ({
    mode,
    email,
    password,
    setFieldValue,
    sendVerificationCode,
}) => {
    const [timer, setTimer] = useState(0);
    const [otpLoading, setOtpLoading] = useState(false);

    useEffect(() => {
        let timerId;
        if (timer > 0) {
            timerId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => {
            clearInterval(timerId);
        };
    }, [timer]);

    useEffect(() => {
        if (mode === "otp") {
            setTimer(270);
        }
    }, [mode]);

    const handleResendCode = async () => {
        await sendVerificationCode(email, password, setOtpLoading, (res) => {
            setFieldValue("otp", "");
            setFieldValue("mode", res.needed_2fa ? "otp" : "");
            if (res.needed_2fa) {
                setTimer(270);
            }
        });
    };

    return (
        <div className="flex">
            {timer > 0 && (
                <p
                    className="text-xs font-medium text-gray-500"
                    style={{
                        textAlign: "right",
                        color: "#939393",
                    }}
                >
                    Resend OTP in {formatTime(timer)}
                </p>
            )}
            {!timer > 0 && (
                <button
                    type="button"
                    onClick={handleResendCode}
                    className="text-xs font-medium text-gray-500"
                    disabled={otpLoading}
                >
                    Resend OTP
                </button>
            )}
        </div>
    );
};

export const getServerSideProps = async ({ query }) => {
    const { reset, redirect_to, share_code, email_change, email } = query;

    return {
        props: {
            ...(share_code && { shareCode: share_code }),
            ...(redirect_to && { redirect_to }),
            ...(email && { email }),
            ...(reset && { reset }),
            ...(email_change && { email_change }),
        },
    };
};

export default Login;
