import Spinner from "@/components/Spinner";
import useLoader from "@/hooks/loader";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const ButtonTextWrapper = ({ text, classes = "" }) => {
    const loader = useLoader();
    const [isLoading, setIsLoading] = useState(false);

    const buttonRef = useRef(null);

    useEffect(() => {
        if (loader.isActive === false) setIsLoading(false);
    }, [loader.isActive]);

    useEffect(() => {
        if (buttonRef.current) {
            const parent = buttonRef?.current?.parentNode;
            parent.addEventListener("mouseup", () => {
                setIsLoading(true);
            });
        }
    }, [buttonRef]);

    return (
        <span className="relative flex items-center" ref={buttonRef}>
            {isLoading && <Spinner classes={classes} />}
            {text}
        </span>
    );
};

const ButtonText = ({
    text,
    loading = false,
    classes = "",
    showCheck = false,
}) => (
    <span className="relative flex items-center">
        {loading && <Spinner classes={classes} />}
        {showCheck && <FontAwesomeIcon className="mr-2" icon={faCheck} />}
        {text}
    </span>
);

export default ButtonText;
