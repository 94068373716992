import { classNames, replaceUnderScoreWithDash } from "@/utilities";
import { useEffect, useRef } from "react";

const FormikInput = ({ field, form: { touched, errors }, ...props }) => {
    let inputStyle;

    const inputRef = useRef();

    switch (props.type) {
        case "checkbox":
            inputStyle =
                "focus:ring-primary-200 focus:ring-opacity-50 h-4 w-4 text-primary-600 border-gray-300 rounded cursor-pointer";
            break;
        default:
            inputStyle =
                "block w-full shadow-sm focus:ring-primary-200 focus:border-primary-300 sm:text-sm border-gray-300 rounded-md focus:ring-opacity-50";
    }

    useEffect(() => {
        if (props.autoFocus) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 100);
        }
    }, []);

    return (
        <div className={classNames("w-full", props?.containerClass)}>
            <input
                id={replaceUnderScoreWithDash(field.name)}
                data-test-id={replaceUnderScoreWithDash(field.name)}
                className={classNames(
                    errors[field.name] && touched[field.name] ? "error" : "",
                    inputStyle + " " + props?.customclassname
                )}
                ref={inputRef}
                {...field}
                {...props}
            />
            {errors[field.name] && touched[field.name] && (
                <span
                    data-test-id={`${replaceUnderScoreWithDash(
                        field.name
                    )}-validation-error`}
                    className="text-red-500 text-xs"
                >
                    {errors[field.name]}
                </span>
            )}
        </div>
    );
};

export default FormikInput;
